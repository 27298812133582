<template>
  <div class="">
    <LoadingScreen :isLoading="loading"></LoadingScreen>
    <el-tabs v-model="status">
      <el-tab-pane label="Todas" name="all" />
      <el-tab-pane v-for="status in filteredStatuses" :key="status.id" :label="status.name" :name="status.id" />
      <el-tab-pane label="Eliminados" name="Deleted" />
    </el-tabs>
    <div class="flex flex-row w-48 lg:w-1/3">
      <div class="flex-1">
        <el-input placeholder="Filtrar..." v-model="search">
          <i slot="prefix" class='mt-2 text-xl bx bx-search'></i>
        </el-input>
      </div>
    </div>
    <div>
      <div v-if="isMobile">
        <div v-for="order of orders" :key="order.id">
          <order-card :order="order" @onDeleteOrder="deleteOrder" @onStatusUpdate="updateStatus" />
        </div>
      </div>
      <div v-else class="py-2">
        <orders-grid :orders="orders" @onDeleteOrder="deleteOrder" @onStatusUpdate="updateStatus"
          @onSorting="handleSorting" />
        <pagination :pagination="pagination" @onPaginationChange="onPaginationChange" />
      </div>
    </div>
  </div>
</template>
<script>
import OrderService from '@/services/OrderService';
import OrderPaymentDialogService, {
  command as OrderPaymentDialogCommand,
  commands as OrderPaymentDialogCommands,
} from '@/components/dialogs/OrderPayment/service';
import { getRestrictedStatuses } from '@/helpers/order-status.helper';
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";

export default {
  name: 'OrdersList',
  props: {
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    outerStatus: {
      type: String,
      default: () => 'all',
    },
  },
  components: {
    OrderCard: () => import('./mobile/OrderCard'),
    OrdersGrid: () => import('./grid/Index'),
    Pagination: () => import('@/components/molecules/Pagination'),
    LoadingScreen: () => import('@/components/layouts/LoadingScreen'),
  },
  data() {
    return {
      loading: true,
      orders: [],
      status: this.outerStatus,
      search: null,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalPages: 0,
      },
      client: false,
      subscription: null,
      headerAppSubscription: null,
    };
  },
  created() {
    this.subscription = OrderPaymentDialogCommand.subscribe(this.handleOrderPaymentDialogCommands);
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(this.handleHeaderAppCommands);
  },
  destroyed() {
    this.subscription.unsubscribe();
    this.headerAppSubscription.unsubscribe();
  },
  mounted() {
    if (this.$route.params.client) {
      this.client = this.$route.params.client;
    }
    this.loadOrders(this.status);
  },
  watch: {
    'search': {
      deep: true,
      handler: async function () {
        await this.loadOrders(this.status, this.search);
      }
    },
    'status': {
      deep: true,
      handler: async function () {
        await this.loadOrders(this.status, this.search);
      }
    },
    'outerStatus': {
      deep: true,
      handler: async function () {
        this.status = this.outerStatus;
      },
    },
  },
  computed: {
    filteredStatuses() {
      // return active statuses
      return this.$store.state.catalogs.order.statuses.filter((status) => status.isActive);
    },
  },
  methods: {
    async loadOrders(selection = 'all', textSearch = '', isChangingPage = false) {
      try {
        const request = {
          businessInfoId: this.$store.state.business.id,
          pageNumber: this.pagination.currentPage,
          pageSize: this.pagination.perPage,
        };
        if (selection === 'Deleted') {
          request.deleted = true;
        }
        else if (selection !== 'all') {
          const currentStatus = this.filteredStatuses.find((s) => s.id === selection);
          if (!currentStatus) {
            // this should no be possible, due all statuses are filtered by isActive
            return;
          }
          request.OrderStatusId = currentStatus.id;
          if (!isChangingPage) {
            this.pagination.currentPage = 1;
          }
        }
        if (textSearch || this.client) {
          request.TextSearch = textSearch || this.client;
          if (!isChangingPage) {
            this.pagination.currentPage = 1;
          }
        }
        const { data: result, metadata } = await OrderService.getAll(request);
        this.pagination = {
          ...this.pagination,
          pageNumber: metadata.pageNumber,
          totalPages: metadata.pages,
        };
        const clientid = this.$route.params.clientid;
        if (clientid) {
          this.orders = result.filter(x => x.client.id === clientid);
        } else if (this.status !== 'all') {
          this.orders = result.filter(x => x.orderStatus.id === this.status);
        } else {
          this.orders = result;
        }
        this.orders = this.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        this.orders = this.orders.map((order) => {
          if (!order.finalizedStatusAt) return { ...order, finalizedStatusAt: null };

          const formattedFinalizedStatusAt = `${order.finalizedStatusAt.toLocaleString()}Z`;

          return {
            ...order,
            finalizedStatusAt: formattedFinalizedStatusAt
          };
        });

        this.loading = false;
      } catch (e) {
        console.log('error', e);
      }
    },
    async deleteOrder(orderId) {
      this.loading = true;
      try {
        await OrderService.deleteOrder(orderId);
        this.orders = this.orders.filter((order) => order.id !== orderId);
        this.$toastr.s('Se borro con exito');
      } catch (e) {
        console.log('error', e);
      } finally {
        this.loading = false;
      }
    },
    async updateOrderStatus({ orderId, status, paymentMethod = undefined, endDate = undefined }) {
      await OrderService.updateStatus({ id: orderId, status, paymentMethod, endDate });
      this.$toastr.s('Se actualizo con exito');
      await this.loadOrders(this.status, this.search);
    },
    updateStatus({ orderId, status }) {
      this.loading = true;
      try {
        const { Finalized } = getRestrictedStatuses(this.$store.state.catalogs.order.statuses);
        if (status === Finalized.id) {
          OrderPaymentDialogService.show({ orderId, status });
        } else {
          this.updateOrderStatus({ orderId, status });
        }
      } catch (e) {
        console.log('error', e);
      } finally {
        this.loading = false;
      }
    },
    handleSorting(sorting) {
      const keys = Object.keys(sorting);
      const direction = sorting[keys];
      this.orders = this.orders.sort((a, b) => {
        if (direction === 'desc') return new Date(b.createdAt) - new Date(a.createdAt);
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
    onPaginationChange(pagination) {
      this.pagination = { ...this.pagination, currentPage: pagination.currentPage };
      this.loadOrders(this.status, this.search, true);
    },
    handleOrderPaymentDialogCommands({ type, payload }) {
      switch (type) {
        case OrderPaymentDialogCommands.Select:
          this.updateOrderStatus({
            orderId: payload.orderId,
            status: payload.status,
            paymentMethod: payload.paymentMethod,
            endDate: payload.endDate
          });
          break;
        default:
      }
    },
    handleHeaderAppCommands({ type }) {
      switch (type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          this.loadOrders(this.status, this.search, false);
          break;
        default:
      }
    }
  },
}
</script>
